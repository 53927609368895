import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import {useRecoilState} from 'recoil';
import { showModal } from '../../store';
import './ContactModal.scss';

export const ContactModal = () => {
    const [show, setShow] = useRecoilState(showModal);
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmission = async () => {
        console.log(fullname, email, message);
        try{
            await axios.post('https://contact.phantomtutor.com', {
                name: fullname,
                email,
                message
            },
            {
                headers: {
                    'Content-Type' : 'application/json'
                }
            });
            setShow(false);
        } catch (ex) {
            
            console.log('FAILED...');
        }
    }

    return (
        <Modal show={show} contentClassName='contactModal' onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Keep in the loop!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='modal-text'>
                    Want to reserve a spot on the waitlist?<br/><br/>
                    Have any questions?<br/><br/>
                    Fill out your info here to get the latest updates about PhantomTutor. 
                </p>
                <Form className='contact-form'>
                    <Form.Control type='text' placeholder='Full Name' onChange={(e) => setFullname(e.target.value)} />
                    <Form.Control type='email' placeholder='Email Address' onChange={(e) => setEmail(e.target.value)} />
                    <Form.Control as='textarea' placeholder='Any additional questions? (optional)' onChange={(e) => setMessage(e.target.value)} />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <button className='submission-button' onClick={() => handleSubmission()}>
                    Send
                </button>
            </Modal.Footer>
        </Modal>
    );
}