import React from 'react';
import miniboo from '../../../images/miniboo.png';
import './Logo.scss';

export const Logo = () => {
    return(
        <button className='logo-button'>
            <img className='logo-button-image' src={miniboo} alt='Bojangles the Ghost' />
            <p className='logo-button-text'>PhantomTutor</p>
        </button>
    );
}