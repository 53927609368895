import React from 'react';
import { InfoBox } from '../Information-Box/InfoBox';
import { ContentBox } from './ContentBox';
//import progress from '../../../images/ProgressCard.png';
//import inquisitive from '../../../images/inquisitive.png';
//import summary from '../../../images/summary.png';
import time from '../../../images/time.svg';
import question from '../../../images/question.svg';
import anywhere from '../../../images/anywhere.svg';
import waste from '../../../images/wastetime.svg';
import './Content.scss';

export const Content = () => (
    <ContentBox>
        <InfoBox 
            header={'Be Inquisitive'} 
            text={`PhantomTutor’s advanced AI understands
            natural language and responds just like 
            a human would. 
            
            No more CTRL + F searching or frantic  
            Googling. Ask questions like you would  
            to your smartest friend.`}
            image={question}
            imgClass='summary'
            reverse={true}
        />

        <InfoBox 
            header={"Don't Waste Time"} 
            text={`Have an urgent question? 

            PhantomTutor is smart enough to give you a 
            quick and concise response right when 
            you need it: anywhere, anytime.`}
            image={waste}
            imgClass='progress'
        />

        <InfoBox 
            header={'Apps coming soon'} 
            text={`Access PhantomTutor on our 
                    web app for dektop and mobile.
                    Our Android and iPhone native apps
                    are coming soon!`}
            image={anywhere}
            imgClass='progress'
            reverse={true}
        />
    </ContentBox>
);