import React from 'react';
import {useRecoilState} from 'recoil';
import { showModal } from '../../../store';
import './EmailBox.scss';

export const EmailBox = () => {
    const [show, setShow] = useRecoilState(showModal);
    return (
        <section className='email'>
            <div className='email-signup'>
                <h3 className='email-signup-header'>
                    Any Questions?
                </h3>
                <p className='email-signup-text'>
                    Shoot us an email to get on 
                    our mailing list, ask us
                    about PhantomTutor, and be the first to
                    know when the latest features release.
                </p>
                <button className='email-signup-button' onClick={() => setShow(true)}>
                    Contact Us
                </button>
            </div>
        </section>
    )
    }