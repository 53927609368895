import React from 'react';
import {useRecoilState} from 'recoil';
import {showModal} from '../../../store';
import girl from '../../../images/clip-girl-study-1.png';
//import wavebg from '../../../images/layered-waves-haikei(1).svg';
import './Hero.scss';

export const Hero = () => {
    const [show, setShow] = useRecoilState(showModal);
    return(
        <section className='hero'>
            <div className='hero-image'>
                <img 
                    className='hero-image-pic'
                    src={girl} 
                    alt='girl laying on a stack of books' />
            </div>

            <div className='hero-copy'>
                <h3 className='hero-copy-header'>Zero-Stress Studying</h3>
                <p className='hero-copy-subheader'>
                    PhantomTutor answers your school questions at any time of day.
                </p>
                <a href='https://app.phantomtutor.com' className='hero-copy-cta'>Sign Up</a>
            </div>
        </section>
    );
}