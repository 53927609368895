import React from "react"
import { Helmet } from "react-helmet";
import { Header } from "../components/header/Header";
import { Hero } from "../components/body/hero/Hero";
import '../styles/index.scss';
import logo from '../images/miniboo.ico';
import { Content } from "../components/body/content-box/Content";
import { Footer } from "../components/footer/Footer";
import { EmailBox } from "../components/body/email-box/EmailBox";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { ContactModal } from "../components/modal/ContactModal";


const IndexPage = () => {
  return (
    <RecoilRoot>
      <main style={{ overflowX : 'hidden'}}>
        <Helmet>
          <title>PhantomTutor</title>
          <link rel="shortcut icon" type="image/png" href={logo}/>

          <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
              />
              
              <meta name="theme-color" content="#000000" />
              <meta name='description' content="The future of learning!" />
            
              <meta itemprop="name" content="PhantomTutor"/>
              <meta itemprop="description" content="The future of learning!"/>
              {/*
              <meta itemprop="image" content="%PUBLIC_URL%/static/media/kologo.a1f26cfc.png/"/> 
              */}
              <meta name="twitter:card" content="summary_large_image"/>
              <meta name="twitter:title" content="PhantomTutor"/>
              <meta name="twitter:description" content="The future of learning!"/>
              {/*
              <meta name="twitter:image:src" content="%PUBLIC_URL%/static/media/kologo.a1f26cfc.png"/> 
              */}
              <meta property="og:title" content="PhantomTutor" />
              <meta property="og:type" content="website" />
              <meta property="og:url" content="http://phantomtutor.com/" />
              {/*
              <meta property="og:image" content="%PUBLIC_URL%/static/media/kologo.a1f26cfc.png" />
              */}
              <meta property="og:description" content="The future of learning!" />
              <meta property="og:site_name" content="PhantomTutor" />
              <script async 
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3488757808425778"
                crossOrigin="anonymous">
              </script>
        </Helmet>
        <div className="top-background">
          <Header />
          <ContactModal />
          <Hero />
        </div>
        <Content />
        <EmailBox />
        <Footer />
      </main>
    </RecoilRoot>
  )
}

export default IndexPage;
