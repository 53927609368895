import React from 'react';
import './InfoBox.scss';

export const InfoBox = ({header, text, image, alt, reverse, imgClass = '', textClass = ''}) => {
    return (
        <div className={`infoBox ${reverse ? 'reverse' : ''}`} >
            <div className={`infoBox-text ${textClass}`}>
                <header className='infoBox-text-header'>{header}</header>
                <p className='infoBox-text-copy'>{text}</p>
            </div>
            <img className={`infoBox-image ${imgClass}`} src={image} alt={alt} />
        </div>
    )
}