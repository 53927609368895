import React from 'react';
import { CTAButton } from './CTAButton/CTAButton';
import { Logo } from './Logo/Logo';
import './Header.scss';

export const Header = () => {
    return (
        <header className='header'>
            <Logo />

            <div className='head-buttons'>
                <button>About</button>
                <button>Contact</button>
                <a href='https://app.phantomtutor.com' className='sign-up'>Sign Up</a>
            </div>
        </header>
    );
}