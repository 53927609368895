import React from "react";
import { Logo } from "../header/Logo/Logo";
import './Footer.scss';

export const Footer = () => {
    return (
        <footer className='footer'>
            <div>
                <Logo />
            </div>
            <p className='footer-copyright'>
                © {new Date().getFullYear().toString()} Phantom-Soft
            </p>
            <div className="footer-links">
                <a 
                    type='email' 
                    href='mailto://info@phantom-soft.com'
                    className='footer-links-email'
                >
                    info@phantom-soft.com
                </a>
                <span>
                    <a
                        href="/legal#terms"
                        className="footer-links-terms"
                    >
                        Terms & Conditions
                    </a>
                    {' | '}
                    <a
                        href="/legal#privacy-policy"
                        className="footer-links-terms"
                    >
                        Privacy Policy
                    </a>
                    
                </span>
            </div>
        </footer>
    );
}